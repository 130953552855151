<template>
	<div class="game-item-div">
		<a class="game-items aspect-square" :href="'/game/' + game.handle" :style="customStyle">
			<img class="game-item-icon"
				:style="customStyle"
				:src="game.thumb"
				:alt="game.title">
		</a>
		<div class="gane-name" v-if="showTitle">{{ game.title}}</div>
	</div>
</template>

<script>
	export default {
		props: {
			game: {
				type: Object,
				required: true
			},
			showTitle: {
				type: Boolean,
				default: true
			},
			width: {
				type: String,
				default: '100%'
			},
			height: {
				type: String,
				default: '106'
			}
		},
		computed: {
			customStyle() {
				return 'height: ' + this.height + 'px;'
			}
		}
	}
</script>


<style scoped>
	.game-items {
	    display: inline-block;
	    cursor: pointer;
	    width: 100%;
	    overflow: hidden;
	    /* height: 106px; */
	    border-radius: 4px;
	}
	
	.gane-name {
	    overflow: hidden;
	    margin: .35714em;
	    color: #4a4a4a;
	    font-size: .875em;
	    text-overflow: ellipsis;
	    white-space: nowrap;
	    text-align: center;
	    font-family: Dosis;
	}
	
	.game-item-div {
	    height: 122px;
	    /* width: 144px; */
	    -webkit-transition: .2s;
	    transition: .2s;
	    border-radius: 10px;
	    overflow: hidden;
	    position: relative;
	    display: block;
	    /* padding: 2px; */
	    border: 4px solid #fff;
	    border-radius: 5px;
	    background: #fff;
	    text-decoration: none;
	    -webkit-transition: box-shadow .45s cubic-bezier(.23,1,.32,1) 0ms;
	    transition: box-shadow .45s cubic-bezier(.23,1,.32,1) 0ms;
	    box-shadow: 0 1px 6px 0 rgba(50,50,50,.45);
	    margin: 0
	}
	
	.game-item-icon {
	    position: absolute;
	    /* height: 100%; */
	    -o-object-fit: cover;
	    object-fit: cover;
	    -o-object-position: center;
	    object-position: center;
	}
</style>